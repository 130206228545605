import React from "react";

const SingleBlogBanner = ({blogDetails}) => {
    return (
        <>
            <div className="relative sm:pt-[35%] pt-[40%] w-full">
                <img className="absolute top-0 left-0 w-full h-full object-center object-cover" src={blogDetails?.image}  alt={blogDetails?.alttag} title={blogDetails?.imagetitle} />
            </div>
        </>
    )
};

export default SingleBlogBanner