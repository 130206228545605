
import PortalHeader from "../components/admin/portalheader/Portalheader";
import Subscribers from "../components/admin/subscribers/Subscriber";
import React, { useEffect, useContext } from "react";
import AccountContext from "../utils/AccountContext"
import { useNavigate } from 'react-router-dom';


const Subscriber = () => {
    const navigate = useNavigate();

    const { login } = useContext(AccountContext);

    const storedUserInfo = sessionStorage.getItem('userInfo');


    useEffect(() => {
        if (!storedUserInfo) {
            navigate("/admin-login");
        }
    }, [storedUserInfo, navigate]);
  
    return (
        <>
        <PortalHeader>
           <Subscribers/>
            </PortalHeader>

        </>
    )
};

export default Subscriber;
