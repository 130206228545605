// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.richtext .ql-toolbar{
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #F2F3F4;
    z-index: 10;
}
.richtext .ql-container{
    max-height: 300px;
    overflow-y: auto;
}

.home-content-info h2 {margin-top: 26px;}
.home-content-info h3, .home-content-info h4 {margin-top: 20px;}
.home-content-info h2, .home-content-info h3, .home-content-info h4, .home-content-info p {margin-bottom: 10px;}
.home-content-info p a {color: #0B7132;}
.home-content-info ul.list_style {list-style: disc;padding-left: 40px;}
.home-content-info ul.list_style li {margin-bottom: 5px;}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,MAAM;IACN,OAAO;IACP,WAAW;IACX,yBAAyB;IACzB,WAAW;AACf;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA,uBAAuB,gBAAgB,CAAC;AACxC,8CAA8C,gBAAgB,CAAC;AAC/D,2FAA2F,mBAAmB,CAAC;AAC/G,wBAAwB,cAAc,CAAC;AACvC,kCAAkC,gBAAgB,CAAC,kBAAkB,CAAC;AACtE,qCAAqC,kBAAkB,CAAC","sourcesContent":[".richtext .ql-toolbar{\n    position: sticky;\n    top: 0;\n    left: 0;\n    width: 100%;\n    background-color: #F2F3F4;\n    z-index: 10;\n}\n.richtext .ql-container{\n    max-height: 300px;\n    overflow-y: auto;\n}\n\n.home-content-info h2 {margin-top: 26px;}\n.home-content-info h3, .home-content-info h4 {margin-top: 20px;}\n.home-content-info h2, .home-content-info h3, .home-content-info h4, .home-content-info p {margin-bottom: 10px;}\n.home-content-info p a {color: #0B7132;}\n.home-content-info ul.list_style {list-style: disc;padding-left: 40px;}\n.home-content-info ul.list_style li {margin-bottom: 5px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
