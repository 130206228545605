import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/OurGallery.webp";
import ContactInfo from '../components/contact_info/ContactInfo';
import GalleryPage from '../components/gallery/GalleryPage';
import React from "react";
import SEO from '../common/SEO';

const OurGallery = () => {

    return (
        <>
            <SEO title='Explore MyEVPoint Gallery: Latest Event Photos & Product Videos' description='Browse through the MyEVPoint gallery for our latest event photos, product videos, and images. Experience the world of electric vehicle charging with us!' keywords='myevpoint latest event and product image' />
            <Header />
            <InnerPageBanner title='Our Gallery' maxWidth='472px' innerPageBanner={aboutBanner} />
            <GalleryPage />
            <ContactInfo />
            <Footer />

        </>
    )
}

export default OurGallery;
