import React, { useEffect, useState } from 'react';
import { windowScroll } from '../../helpers/ScrollToTop';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../../App.css'
import axios from 'axios';
import ImageIcons from '../../common/ImageIcons';


const BlogPage = () => {

  const [allBlogs, setAllBlogs] = useState([])
  const [imageLoaded, setImageLoaded] = useState(false);

  const removeHTMLTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };


  const fetchBlogs = async () => {
    const response = await axios.get(`${process.env.REACT_APP_URL}/user/getBlogs`);
    const filteredData = response.data.data.filter(item => item.isActive === true);
    const sortedBlogs = filteredData.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));

    setAllBlogs(sortedBlogs)
  }


  useEffect(() => {
    fetchBlogs()
  }, [])

  return (
    <>
      {/* Our Blog Section */}
      <div className="blogs-section pb-20 pt-10 relative">
        <div className="container mx-auto px-2">
          <div className="flex flex-wrap -mx-2">
            {allBlogs.map((item) => {
              const dateObject = new Date(item?.publishedAt);
              const formattedDate = dateObject.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              });

              return (
                <div className="w-full md:w-1/3 px-2 mb-4 mt-2" key={item.id}>
                  <div className="blog-list-info bg-[#fff] rounded p-4 shadow-[0px_0px_25px_0px_rgba(0,0,0,0.15)]">
                    <Link to={`/blogs/${item?.handle}`} onClick={windowScroll}>
                      <div className={`blog-img-info mb-3 ${imageLoaded ? '' : 'bg-gray-300 animate-pulse'}`}>
                        <LazyLoadImage className="w-full rounded-lg cursor-pointer object-center object-cover" src={item?.image} effect="blur" onLoad={handleImageLoad}

                          alt={item.alttag} title={item.imagetitle}
                          placeholderSrc={ImageIcons.loading}
                        />
                      </div>
                    </Link>
                    <div className="blog-content-info lg:p-4">
                      <Link className='hidden md:block' to={`/blogs/${item?.handle}`} onClick={windowScroll}>
                        <h4 className="mb-3 cursor-pointer hover:text-[#0B7132]  hidden md:block"
                          style={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            WebkitLineClamp: 2
                          }}
                        >
                          {item?.title}
                        </h4>

                      </Link>

                      <Link className='md:hidden' to={`/blogs/${item?.handle}`} onClick={windowScroll}>

                        <h4 className="mb-3 cursor-pointer hover:text-[#0B7132]"
                        >
                          {item?.title}
                        </h4>
                      </Link>

                      <p style={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        WebkitLineClamp: 2
                      }}>
                        {removeHTMLTags(item?.description)}
                      </p>
                      <Link to={`/blogs/${item?.handle}`} onClick={windowScroll}>
                        <span className='text-[#0B7132]'>Read More</span>
                      </Link>
                      <div className="blog-autor-info mt-3">
                        <p className="text-[#000] font-semibold">Author:<span className='font-normal'> {item?.author}</span></p>
                        <p className="text-[#000] font-semibold">Published At:<span className='font-normal'> {formattedDate}</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

          </div>
        </div>
      </div>

    </>
  );
};
export default BlogPage;
