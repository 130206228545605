// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-sec-info {
    padding-top: 50px;
}

.social-links-info .flex {
    background-color: #0b7132 
}

.green-hr-line {
    border: none;
    border-bottom: 2px solid #0B7132;
    width: 60px;
    margin: 0px;
}

.footer-sec-info p {
    font-size: 16px;
    line-height: normal;
}

.footer-copyright-info {
    border-top: 1px solid rgb(255 255 255 / 10%);
}

.footer-top-info {
    background-repeat: no-repeat;
    background-position: left bottom, right bottom;
}

@media (min-width: 768px) {
    .footer-top-info {
        background-size: 210px, 160px;
    }

    .footer-copyright-info p {
        font-size: 15px;
    }
}

@media (max-width: 768px) {
    .footer-top-info {
        background-size: 130px, 115px;
    }

    .footer-copyright-info p {
        font-size: 14px;
    }
}

#btn-back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI;AACJ;;AAEA;IACI,YAAY;IACZ,gCAAgC;IAChC,WAAW;IACX,WAAW;AACf;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,4CAA4C;AAChD;;AAEA;IACI,4BAA4B;IAC5B,8CAA8C;AAClD;;AAEA;IACI;QACI,6BAA6B;IACjC;;IAEA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,6BAA6B;IACjC;;IAEA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,aAAa;AACjB","sourcesContent":[".footer-sec-info {\n    padding-top: 50px;\n}\n\n.social-links-info .flex {\n    background-color: #0b7132 \n}\n\n.green-hr-line {\n    border: none;\n    border-bottom: 2px solid #0B7132;\n    width: 60px;\n    margin: 0px;\n}\n\n.footer-sec-info p {\n    font-size: 16px;\n    line-height: normal;\n}\n\n.footer-copyright-info {\n    border-top: 1px solid rgb(255 255 255 / 10%);\n}\n\n.footer-top-info {\n    background-repeat: no-repeat;\n    background-position: left bottom, right bottom;\n}\n\n@media (min-width: 768px) {\n    .footer-top-info {\n        background-size: 210px, 160px;\n    }\n\n    .footer-copyright-info p {\n        font-size: 15px;\n    }\n}\n\n@media (max-width: 768px) {\n    .footer-top-info {\n        background-size: 130px, 115px;\n    }\n\n    .footer-copyright-info p {\n        font-size: 14px;\n    }\n}\n\n#btn-back-to-top {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
