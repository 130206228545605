import React from 'react';
import Header from "../components/header/Header";
import Cancellation from "../components/termsPrivacy/Cancellation";
import Footer from "../components/footer/Footer";
import SEO from "../common/SEO";
// import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/CancellationPolicy.webp";
import InnerPageBanner from '../components/shared/InnerPageBanner';


const Cancellations = () => {
   

    return (
        <>
            <SEO title='Cancellation Policy | MyEvPoint' description='Learn about the cancellation policy at MyEvPoint. Find details on canceling orders, refunds, and terms for our electric vehicle charging solutions.' />
            <Header />
            <InnerPageBanner title='Cancellation Policy' maxWidth='472px' innerPageBanner={aboutBanner} />
            <Cancellation />
            <Footer />
        </>
    )
}
export default Cancellations;