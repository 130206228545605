// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Single blog page css */
@media (min-width: 1024px) {
    .single-blog-page h1 {
        font-size: 44px;
        line-height: normal;
        margin-bottom: 10px;
    }

    .single-service-right-sidebar h2 {
        font-size: 34px;
        line-height: normal;
        margin-bottom: 10px;
    }

    .single-service-right-sidebar h3 {
        font-size: 26px;
        line-height: normal;
        margin-bottom: 10px;
    }

    .single-service-right-sidebar h4 {
        font-size: 22px;
        line-height: normal;
        margin-bottom: 10px;
    }
}

@media (max-width: 1024px) {
    .single-blog-page h1 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 10px;
    }

    .single-service-right-sidebar h2 {
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 10px;
    }

    .single-service-right-sidebar h3 {
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 10px;
    }

    .single-service-right-sidebar h4 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/blog/blog.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI;QACI,eAAe;QACf,mBAAmB;QACnB,mBAAmB;IACvB;;IAEA;QACI,eAAe;QACf,mBAAmB;QACnB,mBAAmB;IACvB;;IAEA;QACI,eAAe;QACf,mBAAmB;QACnB,mBAAmB;IACvB;;IAEA;QACI,eAAe;QACf,mBAAmB;QACnB,mBAAmB;IACvB;AACJ;;AAEA;IACI;QACI,eAAe;QACf,iBAAiB;QACjB,mBAAmB;IACvB;;IAEA;QACI,eAAe;QACf,iBAAiB;QACjB,mBAAmB;IACvB;;IAEA;QACI,eAAe;QACf,iBAAiB;QACjB,mBAAmB;IACvB;;IAEA;QACI,eAAe;QACf,iBAAiB;QACjB,mBAAmB;IACvB;AACJ","sourcesContent":["/* Single blog page css */\n@media (min-width: 1024px) {\n    .single-blog-page h1 {\n        font-size: 44px;\n        line-height: normal;\n        margin-bottom: 10px;\n    }\n\n    .single-service-right-sidebar h2 {\n        font-size: 34px;\n        line-height: normal;\n        margin-bottom: 10px;\n    }\n\n    .single-service-right-sidebar h3 {\n        font-size: 26px;\n        line-height: normal;\n        margin-bottom: 10px;\n    }\n\n    .single-service-right-sidebar h4 {\n        font-size: 22px;\n        line-height: normal;\n        margin-bottom: 10px;\n    }\n}\n\n@media (max-width: 1024px) {\n    .single-blog-page h1 {\n        font-size: 30px;\n        line-height: 40px;\n        margin-bottom: 10px;\n    }\n\n    .single-service-right-sidebar h2 {\n        font-size: 26px;\n        line-height: 32px;\n        margin-bottom: 10px;\n    }\n\n    .single-service-right-sidebar h3 {\n        font-size: 22px;\n        line-height: 27px;\n        margin-bottom: 10px;\n    }\n\n    .single-service-right-sidebar h4 {\n        font-size: 18px;\n        line-height: 24px;\n        margin-bottom: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
