import React, { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import VerifiedIcon from '@mui/icons-material/Verified';
import Button from "@mui/material/Button";
import axios from "axios";
import Loader from "../Loader";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';





const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 292,
    bgcolor: 'background.paper',

    border: '1px solid #000',
    p: 4,
    textAlign: 'center'
};


const Editor = ({ placeholder, onEditorChange }) => {
    const [editorHtml, setEditorHtml] = useState('');

    const handleChange = useCallback((html) => {
        setEditorHtml(html);
        onEditorChange(html);
    }, [onEditorChange]);


    useEffect(() => {
    }, [editorHtml]);

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            // [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link'],
            ['clean']

        ],

        clipboard: {
            matchVisual: true,

        }
    };




    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ];



    return (
        <div style={{ width: '660px' }}>
            <ReactQuill
                onChange={handleChange}
                value={editorHtml}
                modules={modules}
                formats={formats}
                bounds={'.app'}
                placeholder={placeholder}
                style={{ width: '100%' }}
                className='richtext relative'
                preserveWhitespace

            />

        </div>
    );
};

Editor.propTypes = {
    placeholder: PropTypes.string,
    onEditorChange: PropTypes.func.isRequired,
};


const AddBlog = ({ setTrigger }) => {



    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile && !selectedFile.type.startsWith("image/")) {
            setError(prevState => ({ ...prevState, success: false, message: "Please select a valid image file." }));
            return;
        }

        setImage(selectedFile);
        e.target.value = null;
    };


    const handleTitleChange = (e) => {
        const inputText = e.target.value;
        setTitle(inputText);
        const formattedTitle = inputText.replace(/:+/g, '').replace(/\s+/g, '-');
        setHandle(formattedTitle.toLowerCase());
    };


    const [open, setOpen] = useState(false);

    const handleClose = () => { setOpen(false) }


    const [title, setTitle] = useState('');
    const [handle, setHandle] = useState('');
    const [author, setAuthor] = useState('');
    const [image, setImage] = useState(null);
    const [imagetitle, setImagetitle] = useState("");
    const [alt, setAlt] = useState(null);
    const [keyword, setKeyword] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [publishedAt, setpublishedAt] = useState(null);




    // const [description, setDescription] = useState('');

    const [error, setError] = useState({
        handle: false,
        message: "",
        success: false
    });

    const [handleError, setHandleError] = useState('');
    const [disabled, setDisabled] = useState(false);

    const [appHtml, setAppHtml] = useState('');

    const handleEditorChange = (html) => {
        const formattedHtml = html.replace(/(?:\r\n|\r|\n)/g, '<br>');
        setAppHtml(formattedHtml);
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisabled(true);
        setError(prevState => ({ ...prevState, message: "" }));
        if (!title || !handle || !author || !image || !appHtml || !metaTitle || !metaDescription || !publishedAt || !imagetitle || !alt) {
            setError(prevState => ({ ...prevState, success: false, message: "Fields must not be empty!" }));
            setDisabled(false);
        } else {
            const formDataToSend = new FormData();
            formDataToSend.append("title", title);
            formDataToSend.append("handle", handle);
            formDataToSend.append("author", author);
            formDataToSend.append("image", image);
            formDataToSend.append("description", appHtml);
            formDataToSend.append("keyword", keyword);
            formDataToSend.append("metaTitle", metaTitle);
            formDataToSend.append("metaDescription", metaDescription);
            formDataToSend.append("publishedAt", publishedAt);
            formDataToSend.append("imagetitle", imagetitle);
            formDataToSend.append("alttag", alt);
    
            try {
                setError(prevState => ({ ...prevState, success: false }));
                const response = await axios.post(`${process.env.REACT_APP_URL}/user/addBlog`, formDataToSend);
                setTimeout(() => {
                    setDisabled(false);
                    setTitle('');
                    setHandle('');
                    setAuthor('');
                    setImage(null);
                    setAppHtml('');
                     // Clearing the content of the Editor
                    setKeyword('');
                    setMetaTitle('');
                    setMetaDescription('');
                    setpublishedAt('');
                    setImagetitle('');
                    setAlt('');
                }, 1000);
                setTrigger(prev => prev + 1);
            } catch (error) {
                setHandleError(error.response.data.message);
                setDisabled(false);
            }
        }
    };
     
    return (
        <>
            <div className="Categories-form relative z-9 bg-white mx-auto grid rounded-[10px] ">
                <h1 className="uppercase">blogs</h1>
                {disabled && (<Loader />)}
                <form

                    onSubmit={handleSubmit}
                >
                    {handleError ?
                        <p className={`text-[14px] text-left text-[#FF0000] font-semibold mb-[10px] ${handleError ? 'block' : 'hidden '}`}>Error: {handleError}</p>

                        :
                        <p className={`text-[14px] text-left text-[#FF0000] font-semibold mb-[10px] ${error.message ? 'block' : 'hidden '}`}>Error: {error.message}</p>
                    }
                    <Grid className="mb-3" container spacing={2} md={8}>
                        <Grid item md={12}>

                            <label className="form-label">Title</label>

                            <input
                                disabled={disabled}

                                className="inpit_row form-input"
                                name="title"
                                type="text"
                                value={title}
                                onChange={handleTitleChange}
                                placeholder="Enter Title"
                            />

                        </Grid>
                        <Grid item md={12}>

                            <label className="form-label">Handle</label>

                            <input
                                disabled={disabled}
                                className="inpit_row form-input"
                                name="handle"
                                type="text"
                                value={handle}
                                onChange={(e) => setHandle(e.target.value)}
                                placeholder="Enter Handle"
                            />

                        </Grid>
                        <Grid item md={6}>

                            <label className="form-label">Author</label>

                            <input
                                disabled={disabled}
                                className="inpit_row form-input"
                                name="author"
                                type="text"
                                maxLength={30}
                                value={author}
                                onChange={(e) => setAuthor(e.target.value)}
                                placeholder="Enter Author Name"
                            />

                        </Grid>
                        <Grid item md={6}>
                            <label className="form-label">Published At</label>

                            <input
                                disabled={disabled}

                                className="inpit_row form-input"
                                name="title"
                                type="date"
                                value={publishedAt}
                                onChange={(e) => setpublishedAt(e.target.value)}
                                placeholder="Select Date"
                            />

                        </Grid>


                        <Grid item md={12}>

                            <label className="form-label">Enter Keyword</label>

                            <input
                                disabled={disabled}
                                className="inpit_row form-input"
                                name="title"
                                type="text"
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                                placeholder="Enter Keyword"
                            />

                        </Grid>

                        <Grid item md={6}>

                            <label className="form-label">Enter Meta Title</label>

                            <input
                                disabled={disabled}

                                className="inpit_row form-input"
                                name="title"
                                type="text"
                                value={metaTitle}
                                onChange={(e) => setMetaTitle(e.target.value)}
                                placeholder="Enter Title"
                            />

                        </Grid>
                        <Grid item md={6}>

                            <label className="form-label">Enter Meta Description</label>

                            <input
                                disabled={disabled}

                                className="inpit_row form-input"
                                name="title"
                                type="text"
                                value={metaDescription}
                                onChange={(e) => setMetaDescription(e.target.value)}
                                placeholder="Enter Description"
                            />

                        </Grid>

                        
                        <Grid item md={6}>

                            <label className="form-label">Enter Image Title</label>

                            <input
                                disabled={disabled}

                                className="inpit_row form-input"
                                name="title"
                                type="text"
                                value={imagetitle}
                                onChange={(e) => setImagetitle(e.target.value)}
                                placeholder="Enter Title"
                            />
                            <Grid item md={12} mt={2}>
                                <label className="form-label">Enter Alt tag</label>
                                <input
                                    disabled={disabled}
                                    className="inpit_row form-input"
                                    name="title"
                                    type="text"
                                    value={alt}
                                    onChange={(e) => setAlt(e.target.value)}
                                    placeholder="Enter Title"
                                />

                            </Grid>

                        </Grid>
                        <Grid item md={4} mt={6} ml={7}>
                            <label className="form-label">Image</label>
                            <div className="flex flex-row">
                                <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} onChange={(e) => handleImageChange(e)}>
                                    Upload file
                                    <VisuallyHiddenInput type="file" accept="image/png, image/jpg, image/jpeg,image/svg,image/webp" />
                                </Button>

                            </div>
                            <div className="ml-2 ">
                                {image ? <p className="text-base ">{image?.name}</p> : <p className="text-base">no file chosen</p>}
                            </div>
                        </Grid>


                        <Grid item md={12}>
                            <div className=" mb-4">
                                <label className="form-label">Enter Description</label>

                                <Editor placeholder={'Enter Description...'} onEditorChange={handleEditorChange} />
                                <label className="form-label mt-4">Description</label>

                                <div
                                    disabled={disabled}
                                    className=" mb-4 inpit_row form-input"
                                    name="description"
                                    style={{ height: "10rem" }}
                                    placeholder="Enter Description"
                                    value={appHtml}
                                    dangerouslySetInnerHTML={{ __html: appHtml }}

                                />
                            </div>
                        </Grid>
                    </Grid>

                    <button className="form-action w-60 " type="Submit">
                        Add
                    </button>
                </form>
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="keep-mounted-modal-title" variant="h6" component="h2" style={{ paddingTop: "20px" }}>
                            Blog Added Successfully <VerifiedIcon style={{ position: "absolute", top: "20px", left: "46%", color: "#1aa13e", fontSize: "30px" }} />
                        </Typography>
                    </Box>
                </Modal>
            </div>
        </>
    );
    ;
}

export default AddBlog;
