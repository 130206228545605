// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-content-box::before {
    background: linear-gradient(186deg, #0B7132 34.9%, #fff0 35.2%);
}
`, "",{"version":3,"sources":["webpack://./src/components/contact/ContactUs.css"],"names":[],"mappings":"AAAA;IACI,+DAA+D;AACnE","sourcesContent":[".detail-content-box::before {\n    background: linear-gradient(186deg, #0B7132 34.9%, #fff0 35.2%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
