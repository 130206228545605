import * as React from "react";
import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Delete from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CloseIcon from '@mui/icons-material/Close';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import VisibilityIcon from '@mui/icons-material/Visibility';




const style = {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: '70%',
    maxHeight: '90%',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    py: 2,
    px: 4
};

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.white,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const formStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-around",
    paddingLeft: "3em",
    marginBottom: "2em",
};




const AllBlogs = ({ trigger }) => {




    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [detail, setDetail] = useState('')
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedData, setSelectedData] = useState(null)
    const [imgPath, setImgPath] = useState('')
    const [change, setChange] = useState(0)
    const [originalFormData, setOriginalFormData] = useState({
        id: "",
        isActive: false,
        title: "",
        handle: "",
        author: "",
        image: null,
        description: "",
        publishedAt: "",
        keyword: "",
        metaTitle: "",
        metaDescription: "",
        imagetitle: "",
        alttag: ""
    });
    const [formDataUpdate, setFormDataUpdate] = useState({
        id: "",
        isActive: false,
        title: "",
        handle: "",
        author: "",
        image: null,
        description: "",
        publishedAt: "",
        keyword: "",
        metaTitle: "",
        metaDescription: "",
        imagetitle: "",
        alttag: ""
    })

    const handleClose = () => setOpen(false);



    const handleView = (item) => {
        setOpen(true);
        setDetail(item)
    };

    const handleEdit = (newdata) => {
        setSelectedData(newdata);
        setOpenEdit(true);
        setOriginalFormData({
            id: newdata._id,
            isActive: newdata.isActive,
            title: newdata.title,
            handle: newdata.handle,
            author: newdata.author,
            image: newdata.image,
            description: newdata.description,
            publishedAt: newdata.publishedAt,
            keyword: newdata.keyword,
            metaTitle: newdata.metaTitle,
            metaDescription: newdata.metaDescription,
            imagetitle: newdata.imagetitle,
            alttag: newdata.alttag
        });
    };

    useEffect(() => {
        if (selectedData) {
            setFormDataUpdate({
                id: selectedData._id,
                isActive: selectedData.isActive,
                title: selectedData.title,
                handle: selectedData.handle,
                author: selectedData.author,
                image: selectedData.image,
                description: selectedData.description,
                publishedAt: selectedData.publishedAt,
                keyword: selectedData.keyword,
                metaTitle: selectedData.metaTitle,
                metaDescription: selectedData.metaDescription,
                imagetitle: selectedData.imagetitle,
                alttag: selectedData.alttag

            })
        }
    }, [selectedData])

    const handleChangeUpdate = async (e) => {

        const { name } = e.target

        setFormDataUpdate((prevData) => {

            return {
                ...prevData,
                [name]: e.target.value
            };
        })

    }

    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];

        setImgPath(selectedFile)
        const updatedFormData = { ...formDataUpdate };

        updatedFormData.image = URL.createObjectURL(selectedFile);

        setFormDataUpdate(updatedFormData);


    };

    const handleSwitch = (event) => {
        setFormDataUpdate((prevData) => ({
            ...prevData,
            isActive: event.target.checked,
        }));
    };

    const handleCancelEdit = () => {
        setOpenEdit(false);
        // Reset the form data to the original values when the modal is closed without saving
        setFormDataUpdate(originalFormData);
        setImgPath(originalFormData.image);
    };


   



    const handleEditBlog = async () => {

        try {
            if (imgPath) {
                const updatedFormDataImagePath = { ...formDataUpdate };
                updatedFormDataImagePath.image = imgPath;

                setFormDataUpdate(updatedFormDataImagePath);
                setChange(prev => prev + 1)
            } else {
                setFormDataUpdate(formDataUpdate);
                setChange(prev => prev + 1)

            }
            setTimeout(() => {
                fetchData();
            }, 2000)


        } catch (error) {
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (formDataUpdate) {

                    const updatedFormData = new FormData();

                    // Iterate over the key-value pairs in formDataUpdate and append them to updatedFormData
                    for (const [key, value] of Object.entries(formDataUpdate)) {
                        updatedFormData.append(key, value);
                    }

                    const response = await axios.put(`${process.env.REACT_APP_URL}/user/editBlog/${formDataUpdate.id}`, updatedFormData);
                    alert("Blog updated");
                    setOpenEdit(false);
                }

            } catch (error) {
            }
        };


        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [change]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/user/getblogs`);
            setData(response?.data?.data);
        } catch (error) {
        }
    };


    useEffect(() => {
        fetchData();
    }, [trigger]);

    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);

    const handleDelete = (itemId) => {
        setDeleteItemId(itemId);
        setDeleteConfirmationOpen(true);
    };


    const handleYesDelete = async (itemid) => {
        try {
            // Make an HTTP DELETE request to delete the location
            const response = await axios.delete(`${process.env.REACT_APP_URL}/user/deleteBlogs/${deleteItemId}`);

            // Filter out the deleted location from the current data
            const updatedData = data.filter((item) => item._id !== deleteItemId);
            setData(updatedData);
        } catch (error) {
        }
        finally {
            setDeleteConfirmationOpen(false);
        }
    }
    const handleNoDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    return (
        <>
            {/* <PortalHeader> */}
            <div className="Categories-form relative z-9 bg-white mx-auto grid rounded-[10px] mt-16">
                <TableContainer sx={{ maxWidth: 1000, minWidth: 1000 }}>
                    <Table aria-label="sticky table" className="w-150">
                        <TableHead >
                            <TableRow >
                                <StyledTableCell>
                                    <strong>No.</strong>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <strong>Author</strong>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <strong>Title</strong>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <strong>Handle</strong>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <strong>Description</strong>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <strong>View</strong>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <strong>Status</strong>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <strong>Edit</strong>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <strong>Delete</strong>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {data.map((data, index) => (


                            <TableBody>
                                <StyledTableRow sx={{ borderBottomWidth: 1 }}>
                                    <StyledTableCell>{index + 1}</StyledTableCell>
                                    <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >{data?.author}</StyledTableCell>
                                    <StyledTableCell sx={{ maxWidth: 150, minWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >{data?.title}</StyledTableCell>
                                    <StyledTableCell sx={{ maxWidth: 150, minWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >{data?.handle}</StyledTableCell>
                                    <StyledTableCell sx={{ maxWidth: 150, minWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >{data?.description}</StyledTableCell>
                                    <StyledTableCell>
                                        <IconButton aria-label="edit" size="large" color="info" onClick={() => handleView(data)}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <IconButton style={{ color: data?.isActive ? "#0B7132" : "#e50914" }} aria-label="delete" size="medium">
                                            <CheckCircleOutlineIcon />
                                        </IconButton>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <IconButton aria-label="edit" size="large" color="primary" onClick={() => handleEdit(data)}>
                                            <ModeEditIcon />
                                        </IconButton>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <IconButton
                                            aria-label="edit"
                                            size="large"
                                            color="inherit"
                                            onClick={() => handleDelete(data?._id)}>
                                            <Delete />
                                        </IconButton>

                                    </StyledTableCell>

                                </StyledTableRow>

                            </TableBody>


                        ))}
                    </Table>

                </TableContainer>

            </div >

            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <div className="dashboard">
                    <Box sx={style}>

                        <main className="mt-2">

                            <div className="mb-4 md:mb-0 w-full mx-auto relative" onClick={handleClose}>
                                <div className="text-right">
                                    <IconButton aria-label="edit" color="inherit"  >
                                        <CloseIcon sx={{ fontSize: 30 }} />
                                    </IconButton>
                                </div>
                                <div className="px-4 lg:px-0 mb-10">
                                    <h2 className="text-4xl font-semibold text-gray-800 leading-tight text-center">
                                        {detail?.title}
                                    </h2>


                                </div>
                                {detail?.image &&
                                    <img src={detail?.image} className="h-80 object-cover lg:rounded mx-auto mt-5" alt="car" />
                                }
                            </div>

                            <div className="flex flex-col lg:flex-row lg:space-x-12">

                                <div className="px-4 lg:px-0 mt-12 text-gray-700 text-lg leading-relaxed w-full lg:w-full">
                                    <p className="pb-6" dangerouslySetInnerHTML={{ __html: detail?.description }}></p>
                                </div>

                            </div>
                            <div className=" flex flex-row  mb-4  w-12/12">
                                <div className="w-4/12 " >
                                    <p className="text-black font-medium text-lg "
                                    >
                                        By :
                                    </p>
                                </div>
                                <div className="w-8/12">
                                    <p className="text-lg " >{detail?.author}</p>
                                </div>
                            </div>
                            <div className=" flex flex-row justify-between mb-4 ">
                                <div className="w-4/12 " >
                                    <p className="text-black font-medium text-lg "
                                    >
                                        Handle :
                                    </p>
                                </div>
                                <div className="w-8/12">
                                    <p className="text-lg" >{detail?.handle}</p>
                                </div>
                            </div>
                            <div className=" flex flex-row justify-between mb-4 ">
                                <div className="w-4/12 flex-end " >
                                    <p className="text-black font-medium text-lg "
                                    >
                                        PublishedAt :
                                    </p>
                                </div>
                                <div className="w-8/12">
                                    <p className="text-lg" >{detail?.publishedAt}</p>
                                </div>
                            </div>
                            <div className=" flex flex-row justify-between mb-4 ">
                                <div className="w-4/12 " >
                                    <p className="text-black font-medium text-lg "
                                    >
                                        Keyword :
                                    </p>
                                </div>
                                <div className="w-8/12">
                                    <p className="text-lg" >{detail?.keyword}</p>
                                </div>
                            </div>
                            <div className=" flex flex-row justify-between mb-4 ">
                                <div className="w-4/12" >
                                    <p className="text-black font-medium text-lg "
                                    >
                                        Meta Title :
                                    </p>
                                </div >
                                <div className="w-8/12">
                                    <p className="text-lg" >{detail?.metaTitle}</p>
                                </div>
                            </div>
                            <div className=" flex flex-row justify-between mb-4 ">
                                <div className="w-4/12 ">
                                    <p className="text-black font-medium text-lg "
                                    >
                                        Meta Description :
                                    </p>
                                </div>
                                <div className="w-8/12">
                                    <p className="text-lg" >{detail?.metaDescription}</p>
                                </div>
                            </div>
                            <div className=" flex flex-row justify-between mb-4 ">
                                <div className="w-4/12">
                                    <p className="text-black font-medium text-lg "
                                    >
                                        Image Title :
                                    </p>
                                </div>
                                <div className="w-8/12">
                                    <p className="text-lg" >{detail?.imagetitle}</p>
                                </div>
                            </div>
                            <div className=" flex flex-row justify-between mb-4 ">
                                <div className="w-4/12 ">
                                    <p className="text-black font-medium text-lg "
                                    >
                                        Image Alt Tag:
                                    </p>
                                </div>
                                <div className="w-8/12">
                                    <p className="text-lg" >{detail?.alttag}</p>
                                </div>
                            </div>
                        </main>

                    </Box>
                </div>
            </Modal>

            <Modal
                keepMounted
                open={openEdit}
                onClose={handleCancelEdit}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"

            >
                <div className="dashboard">
                    <Box sx={style}>

                        <div className="Categories-form relative z-9 bg-white mx-auto grid rounded-[10px]">
                            <div className="flex items-center justify-between">
                                <h1 className="uppercase">Update Blog</h1>
                                <FormGroup>
                                    <FormControlLabel control={<Switch
                                        checked={formDataUpdate?.isActive}
                                        onChange={handleSwitch}
                                        inputProps={{ "aria-label": "controlled" }}
                                    />}
                                        label={(formDataUpdate?.isActive) ? 'Active' : "In-active"}
                                    />
                                </FormGroup>
                            </div>
                            <form
                                style={formStyle}
                                // action="/blogs"
                                // method="post"
                                // encType="multipart/form-data"
                                onSubmit={(e) => { e.preventDefault(); handleEditBlog(formDataUpdate.id); }}

                            >

                                <Grid className="mb-5 flex " container spacing={2} md={12}>
                                    <Grid item md={8} container spacing={2}>
                                        <Grid item md={10} >
                                            <label className="form-label">Title</label>
                                            <input
                                                className="inpit_row form-input"
                                                name="title"
                                                type="text"
                                                // value={data1.name}
                                                value={formDataUpdate.title}
                                                onChange={handleChangeUpdate}
                                                //   onChange={handleChange}
                                                placeholder="Enter your Name"
                                            // setError(null);
                                            />
                                        </Grid>
                                        <Grid item md={10}>
                                            <label className="form-label">Handle</label>
                                            <input
                                                className="inpit_row form-input"
                                                name="handle"
                                                type="text"
                                                // value={data1.name}
                                                value={formDataUpdate.handle}
                                                onChange={handleChangeUpdate}
                                                //   onChange={handleChange}
                                                placeholder="Enter Handle"

                                            // setError(null);
                                            />
                                        </Grid>
                                        <Grid item md={5}>
                                            <label className="form-label">Author</label>
                                            <input
                                                className="inpit_row form-input"
                                                name="author"
                                                type="text"
                                                // value={data1.name}
                                                value={formDataUpdate.author}
                                                onChange={handleChangeUpdate}
                                                //   onChange={handleChange}
                                                placeholder="Enter Author Name"

                                            // setError(null);
                                            />
                                        </Grid>
                                        <Grid item md={5}>
                                            <label className="form-label">Published At</label>
                                            <input
                                                className="inpit_row form-input"
                                                name="publishedAt"
                                                type="date"
                                                // value={data1.name}
                                                value={formDataUpdate.publishedAt}
                                                onChange={handleChangeUpdate}
                                                //   onChange={handleChange}
                                                placeholder="Enter Author Name"

                                            // setError(null);
                                            />
                                        </Grid>
                                        <Grid item md={10}>
                                            <label className="form-label">Enter Keyword</label>
                                            <input
                                                className="inpit_row form-input"
                                                name="keyword"
                                                type="text"
                                                // value={data1.name}
                                                value={formDataUpdate.keyword}
                                                onChange={handleChangeUpdate}
                                                //   onChange={handleChange}
                                                placeholder="Enter Author Name"

                                            // setError(null);
                                            />
                                        </Grid>
                                        <Grid item md={5}>
                                            <label className="form-label">Enter Meta Title </label>
                                            <input
                                                className="inpit_row form-input"
                                                name="metaTitle"
                                                type="text"
                                                // value={data1.name}
                                                value={formDataUpdate.metaTitle}
                                                onChange={handleChangeUpdate}
                                                //   onChange={handleChange}
                                                placeholder="Enter Author Name"

                                            // setError(null);
                                            />
                                        </Grid>
                                        <Grid item md={5}>
                                            <label className="form-label">Enter Meta Description </label>
                                            <input
                                                className="inpit_row form-input"
                                                name="metaDescription"
                                                type="text"
                                                // value={data1.name}
                                                value={formDataUpdate.metaDescription}
                                                onChange={handleChangeUpdate}
                                                //   onChange={handleChange}
                                                placeholder="Enter Author Name"

                                            // setError(null);
                                            />
                                        </Grid>

                                        <Grid item md={5}>
                                            <label className="form-label">Enter Image Title</label>
                                            <input
                                                className="inpit_row form-input"
                                                name="imagetitle"
                                                type="text"
                                                // value={data1.name}
                                                value={formDataUpdate.imagetitle}
                                                onChange={handleChangeUpdate}
                                                //   onChange={handleChange}
                                                placeholder="Enter Author Name"

                                            // setError(null);
                                            />
                                            <Grid item md={15} mt={2}>
                                                <label className="form-label">Enter Alt Tag </label>                                                <input
                                                    className="inpit_row form-input"
                                                    name="alttag"
                                                    type="text"
                                                    // value={data1.name}
                                                    value={formDataUpdate.alttag}
                                                    onChange={handleChangeUpdate}
                                                    //   onChange={handleChange}
                                                    placeholder="Enter Author Name"

                                                // setError(null);
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item md={5} mt={7} ml={3}>
                                            <label className="form-label">Image</label>
                                            <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} onChange={handleImageChange}>
                                                Upload file
                                                <VisuallyHiddenInput type="file" accept="image/png, image/jpg, image/jpeg,image/svg,image/webp" />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={4} container spacing={2}>
                                        <Grid item md={12} >

                                            <img src={formDataUpdate?.image} className="h-80 object-cover lg:rounded  mt-5 " alt="car" />


                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid className="mb-3 flex" container spacing={2} md={12}>
                                    <Grid item md={12}>
                                        <label className="form-label">Description</label>

                                        <textarea
                                            className=" mb-4 inpit_row form-input"
                                            name="description"
                                            style={{ height: "10rem" }}
                                            placeholder="Enter Description"
                                            value={formDataUpdate.description}
                                            onChange={handleChangeUpdate}
                                        />
                                    </Grid>
                                </Grid>
                                <button className="form-action w-60 pt-2 pb-2" type="Submit">

                                    {/* <button className="form-action w-60 pt-2 pb-2" type="Submit" onClick={handleEditBlog}> */}
                                    Update
                                </button>
                            </form>
                            {/* </Box>  */}
                        </div>

                    </Box>
                </div>
            </Modal>
            <Dialog
                open={deleteConfirmationOpen}
                onClose={handleNoDelete}
                aria-labelledby="delete-confirmation-dialog-title"
                aria-describedby="delete-confirmation-dialog-description"
            >
                <DialogTitle id="delete-confirmation-dialog-title">Delete Confirmation</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this location?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleYesDelete} variant="contained" color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleNoDelete} variant="contained" style={{ backgroundColor: "#333333", color: "#ffffff" }}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            {/* </PortalHeader> */}
        </>
    );
};

export default AllBlogs;